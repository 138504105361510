@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Pacifico&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto";
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
}

iframe {
  pointer-events: all;
  width: 0px !important;
  height: 0px !important;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 10px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  border: 1px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
.container {
  margin: 0 auto;
  max-width: 1920px;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* button */
button {
  cursor: pointer;
  border: none;
  opacity: 1;
  outline: none;
}
button:hover {
  opacity: 0.9;
}
button:focus {
  outline: none;
}
/* end button */

/* images */
/* img {
  width: auto;
  height: auto;
  overflow: hidden;
  object-fit: contain;
} */
.img-absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.auto-height {
  height: auto;
}
.bg-blur {
  width: 100%;
  height: 12px;
  position: absolute;
  bottom: -2px;
  background-color: white;
  filter: blur(1px);
}
.cover {
  object-fit: cover;
}
.contain {
  object-fit: contain;
}
.absolute-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.petal {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  top: 0;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
}
.bf-bg {
  width: 1920px;
  height: 100%;
  bottom: 0;
}
/* end images */

/* input */
input::placeholder {
  color: #ffffff;
}
/* end input */

/* icon */
.icon {
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}
.slogan {
  font-family: "Akaya Telivigala", cursive;
}
.akaya {
  font-family: "Akaya Telivigala", cursive;
}

/* swiper */
.swiper-container {
  position: relative;
  width: 100%;
  height: auto;
}

.swiper-slide {
  width: 100%;
  height: auto;
}

.slide-child-pagination {
  display: flex;
  flex: 1;
  height: 6px;
  background-color: lightgrey;
  margin: 24px 0px 0px 8px;
  border-radius: 4px;
}
.swiper-pagination-bullet {
  display: flex;
  flex: 1;
  height: 6px;
  background-color: lightgrey;
  margin: 24px 0px 0px 8px;
  border-radius: 4px;
}

.slide-active,
.swiper-pagination-bullet-active {
  background-image: linear-gradient(
    to right top,
    #6f0d0d,
    #882d28,
    #a04943,
    #b8645f,
    #cf807d,
    #d38685,
    #d88d8d,
    #dc9395,
    #cf8589,
    #c3777c,
    #b66971,
    #aa5b65
  );
  transition: 350ms;
}
.news-active {
  background-image: linear-gradient(
    to right top,
    #6f0d0d,
    #882d28,
    #a04943,
    #b8645f,
    #cf807d,
    #d38685,
    #d88d8d,
    #dc9395,
    #cf8589,
    #c3777c,
    #b66971,
    #aa5b65
  );
  color: #ffffff;
  opacity: 0.8;
}
.slash {
  height: 100%;
  width: 1px;
  background-color: #e0e1e1;
  transform: rotate(45deg);
}
.more-news {
  background-image: linear-gradient(
    to right top,
    #6f0d0d,
    #882d28,
    #a04943,
    #b8645f,
    #cf807d,
    #d38685,
    #d88d8d,
    #dc9395,
    #cf8589,
    #c3777c,
    #b66971,
    #aa5b65
  );
  cursor: pointer;
  filter: blur(0.5px);
}

video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* font  size and font weight*/
/* padding and margin */
.margin-24 {
  margin: 24px;
}
.margin-top-24 {
  margin-top: 24px;
}
.margin-bottom-24 {
  margin-bottom: 24px;
}
.margin-left-24 {
  margin-left: 24px;
}
.margin-right-24 {
  margin-right: 24px;
}

.padding-24 {
  padding: 24px;
}
.padding-top-24 {
  padding-top: 24px;
}
.padding-bottom-24 {
  padding-bottom: 24px;
}
.padding-left-24 {
  padding-left: 24px;
}
.padding-right-24 {
  padding-right: 24px;
}

.padding-8 {
  padding: 8px;
}
.padding-top-8 {
  padding-top: 8px;
}
.padding-bottom-8 {
  padding-bottom: 8px;
}
.padding-left-8 {
  padding-left: 8px;
}
.padding-right-8 {
  padding-right: 8px;
}

.margin-8 {
  margin: 8px;
}
.margin-top-8 {
  margin-top: 8px;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-right-8 {
  margin-right: 8px;
}

.margin-20 {
  margin: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-20 {
  margin-right: 20px;
}

.margin-16 {
  margin: 16px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-right-16 {
  margin-right: 16px;
}

.padding-16 {
  padding: 16px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-bottom-8 {
  padding-bottom: 16px;
}
.padding-left-16 {
  padding-left: 16px;
}
.padding-right-16 {
  padding-right: 16px;
}

.padding-20 {
  padding: 20px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-right-20 {
  padding-right: 20px;
}

.margin-48 {
  margin: 48px;
}
.margin-top-48 {
  margin-top: 48px;
}
.margin-bottom-48 {
  margin-bottom: 48px;
}
.margin-left-48 {
  margin-left: 48px;
}
.margin-right-48 {
  margin-right: 48px;
}

/* font weight */
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-bold {
  font-weight: bold;
}
.text {
  width: 100%;
  max-width: 484px;
  text-align: left;
}
.error-text {
  color: #ed5249;
  transition: 350ms ease-in-out;
}

/* spacing x */
.space-x-4 {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
/* margin x */
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 0.5rem;
}

/* font size */
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-lg {
  font-size: 2rem;
  line-height: 2.5rem;
}
.text-md {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* background gradient */
.bg-gradient {
  background-image: linear-gradient(
    to right top,
    #6f0d0d,
    #882d28,
    #a04943,
    #b8645f,
    #cf807d,
    #d38685,
    #d88d8d,
    #dc9395,
    #cf8589,
    #c3777c,
    #b66971,
    #aa5b65
  );
  transition: 350ms;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .loading {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: #2c3e50;
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: .25em;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: .25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}

.paymentwall{
  width: 75% !important;
  height: 100% !important;
}
.mobiamo{
  width: 100% !important;
  height: 100% !important;
}

.btn_fix-jam-map {
  background-image: url("images/buttons/jam-map.png");
  background-repeat:no-repeat;
  background-size: contain;
}
.btn_reset-time {
  background-image: url("images/buttons/rs-time.png");
  background-repeat:no-repeat;
  background-size: contain;
}
.btn_fix-minus-exp {
  background-image: url("images/buttons/minus-exp.png");
  background-repeat:no-repeat;
  background-size: contain;
}
.btn_unlock {
  background-image: url("images/buttons/unlock.png");
  background-repeat:no-repeat;
  background-size: contain;
}
.btn_upgrade-pet {
  background-image: url("images/buttons/upgrade-pet.png");
  background-repeat:no-repeat;
  background-size: contain;
}
